import type { RouteLocationRaw } from 'vue-router'

export type BreadcrumbItem = {
  text: string
  to: RouteLocationRaw
}

export function useBreadcrumbs() {
  const breadcrumbs = useState<BreadcrumbItem[]>('breadcrumbs', () => [])

  const setBreadcrumbs = (newBreadcrumbs: BreadcrumbItem[]) => {
    breadcrumbs.value = newBreadcrumbs
  }

  return { breadcrumbs, setBreadcrumbs }
}
